//colors
$text-secondary-color: #fff;
$text-primary-color: #ffffff;
$accent-secondary-color:#0077b5;
$accent-primary-color:rgb(247, 247, 8);
$backdrop-bgc: rgba(0, 0, 0, 1);

//grid
$grid-row-mobile:30px;
$grid-row-tablet:100px;
$grid-row-desktop:150px;

// breakpoints
$mobile:480px;
$tablet:768px;
$desktop:1280px;

$timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$time-amount: 550ms;
$timeoutValue:1000ms;



