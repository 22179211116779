 @import 'utils/styles/main.scss';  
 @import 'utils/styles/slider-animation';
 @import 'utils/styles/animation';

.backdrop{
position: absolute;
    width: 100%;
    height: 100%;
background-color: black;
z-index: -10;
}




.slider{
    position: relative;
    display: flex;
   width: 100%;
    // height:120%;
    // height:100%;
    height:100%;
}

.img{
    top:0;
    right: 0;
    display: block;
    // width: auto;
        width: 100%;
    // height:100%;
    height:auto;
    opacity: 0.3;
    background-size:contain;



}

.imageItem{
    position: absolute;
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;

}


@media screen and (min-width: $mobile) {
    .slider{
        // width: 100%;
        // height:100%; 
    }
    
   
  }
    
  
  @media screen and (max-width: $tablet) {
    .slider{
        // width: 100%;
        // height:100%; 
    }
  }
  
  @media screen and (max-width: $desktop) {
    .slider{
        // width: 100%;
        // height:100%; 
    }
  }
  