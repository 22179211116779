
@font-face {
    font-family: 'GothamPro';
    src: url('../fonts/GothamPro/GothamPro.eot');
    src: local('GothamPro'),
        url('../fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro/GothamPro.woff2') format('woff2'),
        url('../fonts/GothamPro/GothamPro.woff') format('woff'),
        url('../fonts/GothamPro/GothamPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'GothamPro';
//     src: url('../fonts/GothamPro/GothamPro-Medium.eot');
//     src: local('GothamPro-Medium'),
//         url('../fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GothamPro/GothamPro-Medium.woff2') format('woff2'),
//         url('../fonts/GothamPro/GothamPro-Medium.woff') format('woff'),
//         url('../fonts/GothamPro/GothamPro-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }


// @font-face {
//     font-family: 'GothamPro';
//     src: url('../fonts/GothamPro/GothamPro-Bold.eot');
//     src: local('GothamPro Bold'), local('GothamPro-Bold'),
//         url('../fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GothamPro/GothamPro-Bold.woff2') format('woff2'),
//         url('../fonts/GothamPro/GothamPro-Bold.woff') format('woff'),
//         url('../fonts/GothamPro/GothamPro-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }

@font-face {
    font-family: 'GothamPro';
    src: url('../fonts/GothamPro/GothamPro-Light.eot');
    src: local('GothamPro Light'), local('GothamPro-Light'),
        url('../fonts/GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GothamPro/GothamPro-Light.woff2') format('woff2'),
        url('../fonts/GothamPro/GothamPro-Light.woff') format('woff'),
        url('../fonts/GothamPro/GothamPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

// @font-face {
//     font-family: 'GothamPro';
//     src: url('../fonts/GothamPro/GothamPro-Medium.eot');
//     src: local('GothamPro-Medium'),
//         url('../fonts/GothamPro/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GothamPro/GothamProNarrow-Medium.woff2') format('woff2'),
//         url('../fonts/GothamPro/GothamProNarrow-Medium.woff') format('woff'),
//         url('../fonts/GothamPro/GothamProNarrow-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'GothamPro';
//     src: url('../fonts/GothamPro/GothamPro-Bold.eot');
//     src: local('GothamPro Bold'), local('GothamPro-Bold'),
//         url('../fonts/GothamPro/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/GothamPro/GothamProNarrow-Bold.woff2') format('woff2'),
//         url('../fonts/GothamPro/GothamProNarrow-Bold.woff') format('woff'),
//         url('../fonts/GothamPro/GothamProNarrow-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
// }