html {
  box-sizing: border-box;
  background-color: black;

}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body{
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Roboto', 'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, "Space Mono for Powerline, Fira Code, Ubuntu Mono derivative Powerline",
    monospace;
}


h1, h2, h3, h4, h5, h6, p, a, img,  div, span, item, list, link, text, ul{
  margin: 0;
  padding: 0;
}

ul{
  list-style: none;
}
a{
  text-decoration: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.section{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: block;
  width: 100vw;
  height: 100%;
  // height: auto;
}

.container{
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto 10px;
  padding: 30px;
  display: block;
  width: 100%;
}



@media screen and (min-width:$mobile) {
  .container{
    max-width: $tablet;
    margin-bottom: 15px;

  }
}
@media screen and (min-width:$tablet) {
  .container{
    max-width: $desktop;
    margin-bottom: 20px;
    
  }
}

@media screen and (min-width:$desktop) {
  .container{
    max-width: $desktop;
  }
  
}