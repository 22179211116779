@import 'utils/styles/main.scss';
@import 'utils/styles/alert';

.success {
  position: relative;
  // text-align: center;
  margin: 250px auto;
  align-items: center;
  max-width: 1200px;
  // font-family: 'StyeneALC', sans-serif;
  // font-weight: 200;
  // font-size: 25px;
}