.userMenu{
  display: flex;
  align-items: center;



}

.h3{
  margin: 0;
  padding: 0;
}

// .logoutbutton{
//   background-color: #2020B0;
//   padding: 5px 20px;
//   width: auto;
//   height: auto;
//   color: white;
//   margin-left: 30px;
//   cursor: pointer;
// }

.button {
  display: block;
  margin: 0 0 0 30px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #2020B0;
  /* background-color: #3884ff; */
  transition: all 200ms ease;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  padding: 5px 20px;
  width: auto;
  // width: 456px;
  // height: 45px;
}

.button:hover,
.button:focus {
  background-color: #1f65d6;
}